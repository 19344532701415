import React, { useState } from 'react';
import { useStripe, useElements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import Loading from 'commons-ui/core/Loading';
import { dataSourceV2 } from 'commons-ui/core/DataSource';

export const StripeCheckout = ({
  amount,
  products,
  onCreateOrder,
  checkoutValidate,
  onConfirmOrder,
  statesInclude,
  onRedirect,
  noLoading,
  deliveryType,
  onInit,
  ecommerceStoreId,
  recaptchaKey,
  onAmountChange,
  apiPath,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');

  const withShipping = ["shipping_event", "shipping", "delivery"].includes(deliveryType);

  const closeExpressCheckout = () => {
    const element = elements.getElement(ExpressCheckoutElement);
    if (element) {
      console.log('Clearing ExpressCheckoutElement');
      element.clear();
    }
  };

  const onPaymentStart = async (ecommerceOrderId, updatedTotalAmount) => {
    console.log('onPaymentStart called with:', {
      ecommerceOrderId,
      updatedTotalAmount,
    });
    if (window.grecaptcha) {
      try {
        await new Promise((resolve) => {
          window.grecaptcha.ready(resolve);
        });

        const token = await window.grecaptcha.execute(recaptchaKey, { action: 'payment_start' });
        console.log('reCAPTCHA token obtained:', token);

        const response = await dataSourceV2('customer_payment_start_stripe', {
          data: {
            amount: updatedTotalAmount,
            ecommerce_store_id: ecommerceStoreId,
            ecommerce_order_id: ecommerceOrderId,
            captcha_token: token,
          },
          url: apiPath,
        });

        console.log('Payment start response:', response);

        if (response.token) {
          console.log('Client secret received:', response.token);
          return { clientSecret: response.token, token };
        } else {
          console.log('No token in response:', response);
        }
      } catch (error) {
        console.error('Error during reCAPTCHA execution:', error);
        return { message: 'error' };
      }
    } else {
      console.log('window.grecaptcha not available');
    }
    return { message: 'error' };
  };

  const onReady = (data) => {
    console.log('onReady called with data:', data);
    if (!data.availablePaymentMethods) {
      console.log('No available payment methods');
      onInit({ ok: false });
    } else {
      console.log('Available payment methods:', data.availablePaymentMethods);
      setLoading(false);
      onInit({ ok: true });
    }
  };

  const getOrderFromEvent = (event) => {
    console.log('getOrderFromEvent called with event:', event);

    const getName = (nameFull) => (nameFull || '').trim().split(' ')[0] || '';
    const getSurname = (nameFull) => (nameFull || '').trim().split(' ')[1] || '';

    const billingDetails = event.billingDetails || {};
    const billingAddress = billingDetails.address || {};
    const paymentMethod = event.expressPaymentType || '';
    const shippingAddress = event.shippingAddress || {};
    const address = shippingAddress.address || {};

    const order = {
      customer_name: getName(billingDetails.name),
      customer_surname: getSurname(billingDetails.name),
      customer_phone: billingDetails.phone,
      customer_email: billingDetails.email,

      billing_name: billingDetails.name,
      billing_address: (
        (billingAddress.line1 || '') +
        ' ' +
        (billingAddress.line2 || '') +
        ' ' +
        (billingAddress.city || '') +
        ' ' +
        (billingAddress.state || '') +
        ' ' +
        (billingAddress.country || '')
      )
        .trim()
        .split('  ')
        .join(' '),
      billing_postal_code: billingAddress.postal_code,

      delivery_type: deliveryType,
    };
    if (withShipping) {
      order.delivery_name = getName(shippingAddress.name);
      order.delivery_surname = getSurname(shippingAddress.name);
      order.delivery_address = ((address.line1 || '') + ' ' + (address.line2 || '')).trim();
      order.delivery_city = address.city;
      order.delivery_state = address.state;
      order.delivery_postal_code = address.postal_code;
      order.delivery_country = 'US';
      order.delivery_phone = billingDetails.phone;
    }
    console.log('Order constructed from event:', order);
    return order;
  };

  const onConfirm = async (event) => {
    console.log('onConfirm called with event:', event);
    if (!stripe) {
      console.log('Stripe not loaded');
      return;
    }

    let order = getOrderFromEvent(event);

    if (
      withShipping && statesInclude &&
      statesInclude.length && order.delivery_state &&
      !statesInclude.map((s) => (s || "").toUpperCase()).includes(order.delivery_state.toUpperCase())
    ) {
      setErrorMessage('Shipping is only available to ' + statesInclude.map((v) => v.toUpperCase()).join(', '));
      console.log('Shipping state not included:', order.delivery_state);
      closeExpressCheckout();
      return;
    }


    const validateResult = await checkoutValidate(order);
    console.log('Validation result:', validateResult);
    const validateErrors = validateResult.errors || {};
    delete validateResult['errors'];

    if (validateErrors && Object.keys(validateErrors).length) {
      setErrorMessage(validateErrors[Object.keys(validateErrors)[0]]);
      console.log('Validation errors:', validateErrors);
      closeExpressCheckout();
      return;
    }

    if (withShipping) {
      const shippingRate = validateResult.delivery_amount || 0;
      console.log('Shipping rate obtained:', shippingRate);
      if (shippingRate < 0) {
        setErrorMessage('Unable to process your payment at this time. Please try again later. Error #0');
        console.log('Invalid shipping rate:', shippingRate);
        closeExpressCheckout();
        return;
      }
    }
    

    order = { ...order, ...validateResult };
    console.log('Order after merging validateResult:', order);
    const totalAmountUpdated = order.total_amount
    onAmountChange(totalAmountUpdated);

    order.payment_type = event.expressPaymentType || "";
    const orderResponse = await onCreateOrder(order);
    console.log('Order response from onCreateOrder:', orderResponse);

    if (orderResponse.error || orderResponse.message) {
      setErrorMessage('Unable to process your payment at this time. Please try again later. Error #1');
      console.log('Error in orderResponse:', orderResponse.error || orderResponse.message);
      closeExpressCheckout();
      return;
    }

    const ecommerceOrderId = orderResponse.ecommerce_order_id;
    if (!ecommerceOrderId) {
      setErrorMessage('Unable to process your payment at this time. Please try again later. Error #2');
      console.log('No ecommerceOrderId received');
      closeExpressCheckout();
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error(submitError.message)
      setErrorMessage("Unable to process your payment at this time. Please try again later. Error #3");
      closeExpressCheckout()
      return;
    }


    const paymentStartResponse = await onPaymentStart(ecommerceOrderId, totalAmountUpdated);
    console.log('Payment start response:', paymentStartResponse);
    if (paymentStartResponse.message) {
      setErrorMessage('Unable to process your payment at this time. Please try again later. Error #4');
      closeExpressCheckout();
      return;
    }
    const clientSecret = paymentStartResponse.clientSecret;
    if (!clientSecret) {
      setErrorMessage('Unable to process your payment at this time. Please try again later. Error #5');
      console.log('No clientSecret received');
      closeExpressCheckout();
      return;
    }

    const { error: confirmError } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required"
    });

    if (confirmError) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      console.error(confirmError.message)
      setErrorMessage("Payment Error #6");
      closeExpressCheckout()
    } else {
      await onConfirmOrder(ecommerceOrderId)
    }
  };

  const onClick = async ({ resolve }) => {
    console.log('onClick called');
    console.log('Product total amount:', amount);

    let finalAmount = amount;
    //const validateResult = await checkoutValidate({});
    //if (validateResult && validateResult.total_amount) {
    //  finalAmount = Math.round(validateResult.total_amount * 100)
    //  console.log('Product updated total amount:', finalAmount);
    //} 

    const options = {
      country: 'US',
      lineItems: [
        {
          name: 'Products',
          amount: finalAmount
        }
      ],
      emailRequired: true,
      phoneNumberRequired: true,
      ...(
        withShipping
          ? {
            shippingAddressRequired: true,
            allowedShippingCountries: ['US'],
            shippingRates: [
              {
                id: 'placeholder_shipping',
                displayName: 'Shipping',
                amount: 0,
              },
            ],
          }
          :
          { shippingAddressRequired: false }
      )
    };
    console.log('onClick options:', options);
    resolve(options);
  };

  const onShippingAddressChange = async (event) => {
    console.log('onShippingAddressChange called with event:', event);
    const { address, name, resolve, reject } = event;

    // Create a temporary order object for validation
    const tempOrder = {
      delivery_type: 'shipping_event',
      delivery_address: '', // Address lines may not be available
      delivery_city: address.city,
      delivery_state: address.state,
      delivery_postal_code: address.postal_code,
      delivery_country: address.country,
      delivery_name: name,
    };
    console.log('Temporary order for address validation:', tempOrder);

    // Validate the address and get shipping rate
    const validateResult = await checkoutValidate(tempOrder);
    console.log('Validation result:', validateResult);
    const validateErrors = validateResult.errors || {};
    delete validateResult['errors'];

    if (validateErrors && Object.keys(validateErrors).length) {
      console.log('Validation errors:', validateErrors);
      reject();
      return;
    }

    const shippingCost = validateResult.delivery_amount || 0;
    console.log('Shipping cost obtained:', shippingCost);

    if (shippingCost < 0) {
      console.log('Invalid shipping cost:', shippingCost);
      reject();
      return;
    }

    const shippingCostInCents = Math.round(shippingCost * 100); // Convert to cents
    console.log('Shipping cost obtained:', shippingCostInCents);


    if (shippingCostInCents < 0) {
      console.log('Invalid shipping cost:', shippingCostInCents);
      reject(); // Reject if shipping cost is invalid
      return;
    }

    onAmountChange(validateResult.total_amount);
    await new Promise(res => setTimeout(res, 1000));

    // Provide the shipping methods (Note the property names)
    const shippingMethods = [
      {
        id: 'standard_shipping',
        displayName: 'Standard Shipping',
        amount: shippingCostInCents,
      },
    ];
    console.log('Shipping methods to provide:', shippingMethods);

    // Update the shipping options by passing the shipping methods array directly to resolve
    resolve({
      shippingRates: shippingMethods,
      lineItems: [
        {
          name: 'Products',
          amount: Math.round(validateResult.total_amount * 100),
        },
        //{
        //  name: 'Shipping',
        //  amount: shippingCostInCents,
        //}
      ]
    });
    console.log('Shipping options updated via resolve');
  };

  const onShippingRateChange = (event) => {
    console.log('onShippingRateChange called with event:', event);
    const { shippingRate, resolve, reject } = event;

    const selectedShippingCost = shippingRate.amount;
    console.log('Selected shipping rate:', shippingRate);
    console.log('Selected shipping cost:', selectedShippingCost);


    const shippingMethods = [
      {
        id: 'standard_shipping',
        displayName: 'Standard Shipping',
        amount: selectedShippingCost,
      },
    ];
    resolve({
      shippingRates: shippingMethods,
      lineItems: [
        {
          name: 'Products',
          amount: amount,
        }
      ]
    });
    console.log('Total and display items updated via resolve');
  };



  return (
    <>
      <div id="checkout-page" className="u-flexCenter u-justifyContentCenter" style={{ maxWidth: 300 }}>
        {((loading || props.loading) && !noLoading) ? <Loading className="u-marginTop10 u-width80" /> : null}
        <ExpressCheckoutElement
          onConfirm={onConfirm}
          onReady={onReady}
          onClick={onClick}
          {...(withShipping ? { onShippingAddressChange, onShippingRateChange } : {})}
          paymentMethodOrder={['apple_pay', 'google_pay']}
        />
        {errorMessage && (
          <div className="u-marginTop10 u-textColorRed u-fontWeightBold">{errorMessage}</div>
        )}
      </div>
      {
        onRedirect ?
          <div className="u-textColorNormal u-fontSize12 u-marginTop15 u-marginBottom15">
            if you want to pay with credit card{' '}
            <span className="link u-fontWeightBold" onClick={() => onRedirect()}>
              click this link
            </span>
          </div>
          :
          null
      }

    </>
  );
};