import React, {useState, useEffect} from "react";
import "./style.styl";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {dataSourceV2} from "commons-ui/core/DataSource";
import {Form, withFormik} from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import CartContent from "./CartContent";
import Authorization from "./Authorization";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import cookie from "react-cookie";
import Delivery from "./Delivery";
import Contacts from "./Contacts";
import Checkout from "./Checkout";
import {getFromLS} from "commons-ui/core/utils/index";
import Upsell from "ecommerce-commons-ui/core/Upselling";
import UpsellingV2 from "ecommerce-commons-ui/core/UpsellingV2";
import {calcPrice, renderFeatureVertical} from "../../utils";

export const logout = (dispatch) => {
    cookie.remove("token", {path: "/"});
    if (dispatch) {
        dispatch("login", {token: ""});
    }
};


const initDate = () => {
    let now = new Date();
    now.setHours(12);
    return now;
};


export const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



const steps = [
    //{step: 0, title: "Authorization"},
    {step: 1, title: "Order", type: "order"},
    {step: 2, title: "Delivery", type: "delivery"},
    {step: 3, title: "Contacts", type: "contacts"},
    {step: 4, title: "Checkout", type: "checkout"}
];


const stepMapping = {
    auth: 0,
    order: 1,
    delivery: 2,
    contacts: 3,
    checkout: 4
};



const getDisabledSteps = (deliveryItem) => {
    if (Object.keys(deliveryItem).length !== 0 && deliveryItem.delivery_type) {
        const deliveryType = deliveryItem.delivery_type;
        if (deliveryType === "event") {
            return [1, 2];
        } else if (deliveryType === "pick_up") {
            return [1];
        } else if (deliveryType === "delivery") {
            return [1];
        }
    }
    return [];
};


function CheckoutStepForm(props) {
    const {stepName, cart, values, errors, login, isV2, paymentResultPolling, paymentDynamic, setErrors, setTouched, validateForm, touched, onBack, ecommerceStoreId, setValues, setFieldValue, submitForm, isSubmitting, isLogin, checkoutType, noBackButton, height, dispatch, apiPath, isMobile, onSuccess} = props;
    const [step, setStep] = useState(login.token ? 1 : 0) //useState(login.token ? 1 : 0);
    const [externalTransactionId, setExternalTransactionId] = useState("");
    const [paymentStatusCheckAt, setPaymentStatusCheckAt] = useState("");
    const [isValidating, setIsValidating] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const deliveryItem = cart.deliveryItem || {};
    const isDeliverySelected = Object.keys(deliveryItem).length !== 0;
    


    const amount = cart.items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);

    const setValue = async (key, value, options) => {
        options = options || {};
        const update = {...values};
        update[key] = value;
        await setValues(update);
        if (options.submit) {
            submitForm();
        }
    };

    const getStep = () => {
        if (stepName) {
            //step = stepMapping[this.props.stepName] || step;
        }
        return step;
    };

    const goToStep = (step, reverse) => {
        const disabledSteps = getDisabledSteps(deliveryItem);
        for (const item of disabledSteps) {
            if (item === step) {
                return goToStep(step + (reverse ? -1 : 1));
            }
        }
        setStep(step);
    };

    const onPreviousStep = () => goToStep(getStep() - 1, true);

    const onNextStep = () => {
        validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                window.scrollTo(0, 0); goToStep(getStep() + 1);
            } else {
                setErrors(errors);
                setTouched(errors);
            }
        });
    };

    const contactData = () => {
        if (!login.token) {
            return
        }
        dataSourceV2("customer_get", { url: apiPath }).then((v) => {
          if (!v.message && v.item) {
            const customer = v.item || {};
            const {name, surname, email, phone} = customer;
            if (!values.customer_name) {
                setFieldValue("customer_name", name);
            }
            if (!values.customer_surname) {
                setFieldValue("customer_surname", surname);
            }
            if (!values.customer_email) {
                setFieldValue("customer_email", email);
            }
            if (!values.customer_phone) {
                setFieldValue("customer_phone", phone);
            }
          }
        });
    };

    const onCheckPaymentStatus = (externalTransactionId) => {
        if (isV2) return;
        if (values.is_payment_send) {
            return;
        }
        console.log("POLL");
        dataSourceV2(`customer_payment_status_poll?external_transaction_id=${externalTransactionId}`, {url: apiPath})
            .then(v => {
                if (v.status) {
                    if (v.status === "approved") {
                        if (!values.is_payment_done) {
                            setFieldValue("is_payment_done", true).then(v => submitForm());
                        }
                        //onSuccess({})
                    } else if (v.status === "declined") {
                        console.log("stop submitting declined")
                        props.setSubmitting(false);
                        setFieldValue("is_payment", false);
                    } else {
                    }
                }
            });
    };


    const onCardWidgetOpen = () => {
        //submitForm()
        //onSuccess({})
        //return
        if (isV2) return;
        if (!window.emergepay) return;
        props.setSubmitting(true);
        window.emergepay.init();
        dataSourceV2(paymentDynamic ? "customer_payment_dynamic_start" : "customer_payment_start", {data: {ecommerce_store_id: ecommerceStoreId, amount: parseFloat(values.total_amount.toFixed(2)) || 0, billing_name: values.billing_name, billing_address: values.billing_address, billing_postal_code: values.billing_postal_code}, url: apiPath})
            .then(v=>{
                if (v.message) {
                } else if (v.token) {
                    setExternalTransactionId(v.external_transaction_id || "");
                    window.emergepay.open({
                                // (required) Used to set up the modal
                                transactionToken: v.token,
                                // (optional) Callback function that gets called after a successful transaction
                                onTransactionSuccess: (approvalData) => {
                                    //console.log("Approval Data", approvalData);
                                    if (!paymentResultPolling) {
                                        const paymentData = {credit_card_id: approvalData.uniqueTransId, unique_transaction_id: approvalData.uniqueTransId, external_transaction_id: approvalData.externalTransactionId, credit_card_number: approvalData.maskedAccount, credit_card_expiration: approvalData.accountExpiryDate, credit_card_type: approvalData.accountExpiryDate};
                                        setFieldValue("payment", paymentData);
                                        if (!values.is_payment_done) {
                                            setFieldValue("is_payment_done", true).then(v => submitForm());
                                        }
                                        //onSuccess(paymentData)
                                    } else {
                                        if (!values.is_payment_done) {
                                            //setFieldValue("is_payment_done", true).then(v => submitForm())
                                        }
                                        //onSuccess({})
                                    }
                                    window.emergepay.close();
                                },
                                // (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
                                onTransactionFailure: (failureData) => {
                                    console.log("Failure Data", failureData);
                                    setExternalTransactionId("");
                                    console.log("stop submitting transaction failure")
                                    props.setSubmitting(false);
                                    setFieldValue("is_payment", false);
                                },
                                // (optional) Callback function that gets called after a user clicks the close button on the modal
                                onTransactionCancel: function() {
                                    console.log("transaction cancelled!");
                                    setExternalTransactionId("");
                                    console.log("stop submitting transaction cancelled")
                                    props.setSubmitting(false);
                                    setFieldValue("is_payment", false);
                                }
                            });
                }
            });
    };



    const onPaymentSuccess = (data) => {
        if (data) {
            setFieldValue("payment", data);
        }
        setFieldValue("is_payment_done", true).then(v => submitForm());
        props.setSubmitting(true);
    };

    const onPaymentCancel = (data) => {
        console.log("stop submitting payment cancel")
        props.setSubmitting(false);
    };

    const onResetSession = () => {
        console.log("RESET SESSION")
        const ecommerceOrderId = values.ecommerce_order_id;
        if (ecommerceOrderId) {
            props.setFieldValue("is_payment_send", false);
            props.setFieldValue("ecommerce_order_id", "");
        }
    };

    const onResetSessionHard = () => {
        console.log("RESET SESSION HARD")
        window && window.location && window.location.reload && window.location.reload();
    };


    console.log(22, values)

    const checkoutValidate = () => {

        console.log("VAL", values)
        if (values.delivery_type === "shipping" || values.delivery_type === "delivery" || values.delivery_type === "shipping_event") {
            if (!values.delivery_address || !values.delivery_postal_code || !values.delivery_city || !values.delivery_state || !values.delivery_requested_date) {
                return
            }
        }
        setIsValidating(true)
        setIsValidated(false)
        const now = new Date();
        const deliveryRequestedAt = values.delivery_type === "event" ? values.event_date_selected : values.delivery_requested_date;
        const data = {
            is_last_step: step === 4,
            ecommerce_store_delivery_option_id: values.ecommerce_store_delivery_option_id || values.ecommerce_store_delivery_option.ecommerce_store_delivery_option_id,
            ecommerce_store_delivery_option_pickup_id: values.ecommerce_store_delivery_option_pickup_id || values.ecommerce_store_delivery_option_pickup.ecommerce_store_delivery_option_pickup_id,
            ecommerce_store_delivery_option_rule_id: values.ecommerce_store_delivery_option_rule_id || values.ecommerce_store_delivery_option_rule.ecommerce_store_delivery_option_rule_id,
            ecommerce_store_delivery_option_event_id: values.ecommerce_store_delivery_option_event_id || (values.ecommerce_store_delivery_option_event || {}).ecommerce_store_delivery_option_event_id,
            products: cart.items,
            tips_amount_custom: values.tips_amount_custom,
            tips_amount_percent: values.tips_amount_percent,
            ecommerce_store_gift_card_list: values.ecommerce_store_gift_card_list,
            promocode: values.promocode,
            labeling_amount: values.labeling_amount,
            ecommerce_store_id: ecommerceStoreId,
            order_placed_at: now.toISOString(),
            delivery_requested_at: deliveryRequestedAt,
            delivery_name: (values.delivery_name || "") + " " + (values.delivery_surname || ""),
            delivery_address: values.delivery_address,
            delivery_city: values.delivery_city,
            delivery_postal_code: values.delivery_postal_code,
            delivery_state: values.delivery_state,
        }

        dataSourceV2(`ecommerce_order_validate`, {data: data, url: apiPath})
            .then(v => {
                console.log("VALIDATE", v, values)
                if (v.values) {
                    const errors = v.values.errors || {};
                    delete v.values["errors"];
                    if (Object.keys(errors).length) {
                        //setErrors(errors)
                    }
                    Object.keys(v.values).forEach(key => {
                        const value = v.values[key]
                        setFieldValue(key, value)
                    });
                    setFieldValue("validation_errors", errors)

                    setTimeout(() => validateForm(), 300)
                    //const result = { ...values, ...v.values }
                    //setValues(result)
                    setIsValidating(false)
                    setIsValidated(true)
                } else {
                    setIsValidated(false)
                    setIsValidating(false)
                }
            });
    }


    const renderForm = () => {
        const step = getStep();
        const propsDefault = {
            onNextStep: onNextStep,
            onFinish: () => dispatch("/")
        };

        const deliveryType = deliveryItem.delivery_type;
        switch (step) {
            case 1:
                return (
                    <CartContent {...props} {...cart} {...propsDefault} deliveryType={deliveryType}/>
                );
            case 2:
                return (
                    <Delivery {...props}
                              {...propsDefault}
                              checkoutValidate={checkoutValidate}
                              isValidated={isValidated}
                              readOnlySelect={deliveryType === "pick_up" || deliveryType === "delivery" || deliveryType === "event" || deliveryType === "shipping_event" || deliveryType === "shipping"}
                              itemIdParent="ecommerce_store_id"
                              itemIdValueParent={ecommerceStoreId}
                              modelName="ecommerce_store_delivery_option"
                              itemId="ecommerce_store_delivery_option_id"/>
                );
            case 3:
                return (
                    <Contacts {...props} {...propsDefault}/>
                );
            case 4:
                return (
                    <Checkout {...props}
                              {...propsDefault}
                              isValidated={isValidated}
                              disabled={isSubmitting || isValidating || !isValidated}
                        onPaymentCancel={onPaymentCancel}
                        checkoutValidate={checkoutValidate}
                              onPayStart={() => {props.setSubmitting(true);}}
                              onPay={() => onCardWidgetOpen()}
                              isPaymentSend={values.is_payment_send}
                              onPaymentSuccess={onPaymentSuccess}
                              amount={amount}
                              onSubmit={() => submitForm()}/>
                );
        }
        return null;
    };

    useEffect(() => {
        if (step === 4 || step === 2) {
            if ((values.delivery_type === "delivery" || values.delivery_type === "shipping") && Object.keys(values.ecommerce_store_delivery_option_rule).length === 0) {
                return
            }
            if ((values.delivery_type === "shipping_event") && !values.ecommerce_store_delivery_option_event_id) {
                return
            }
            if ((values.delivery_type === "event") && !values.ecommerce_store_delivery_option_event_id) {
                return
            }
            if ((values.delivery_type === "pick_up") && !values.ecommerce_store_delivery_option_pickup_id) {
                return
            }
            checkoutValidate()
        }
    }, [cart, values.promocode, amount, values.tips_amount_percent, values.tips_amount_custom, values.step, (values.ecommerce_store_gift_card_list || []).length, values.labeling_amount, values.ecommerce_store_delivery_option_id, values.ecommerce_store_delivery_option_pickup_id, values.ecommerce_store_delivery_option_rule_id, values.ecommerce_store_delivery_option_event_id, Object.keys(values.ecommerce_store_delivery_option_rule).length])

    useEffect(() => {
        if (values.step !== step) setFieldValue("step", step);
    }, [step]);

    useEffect(() => {
        return
        const amountNum = parseFloat(amount);
        if (values.base_amount !== amountNum) setFieldValue("base_amount", amountNum);
    }, [amount]);

    useEffect(() => {
        return
        const tips = amount / 100 * (values.tips_amount_percent || 0);
        setFieldValue("tips_amount", tips);
    }, [values.tips_amount_percent]);

    useEffect(() => {
        return
        let promocodeAmount = 0
        if (values.ecommerce_store_promocode_discount_percent) {
            promocodeAmount = values.base_amount * (values.ecommerce_store_promocode_discount_percent || 0) / -100;
        } else if (values.ecommerce_store_promocode_discount) {
            promocodeAmount = -1 * values.ecommerce_store_promocode_discount
        }
        setFieldValue("promocode_amount", promocodeAmount);
    }, [values.ecommerce_store_promocode_discount_percent, values.ecommerce_store_promocode_discount]);

    useEffect(() => {
        return
        let giftCardAmount = 0;
        (values.ecommerce_store_gift_card_list || []).forEach(card => {
            giftCardAmount += - (card.amount || 0);
        });
        console.log(values.ecommerce_store_gift_card_list)
        setFieldValue("gift_card_amount", giftCardAmount);
    }, [(values.ecommerce_store_gift_card_list || []).length]);

    console.log(123, values.gift_card_amount)



    useEffect(() => {
        return
        setFieldValue("total_amount", values.base_amount + values.promocode_amount + values.gift_card_amount + values.delivery_amount + values.packaging_amount + values.labeling_amount + values.tips_amount + values.tax_amount);
    }, [values.base_amount, values.promocode_amount, values.gift_card_amount, values.tips_amount, values.delivery_amount, values.packaging_amount, values.labeling_amount, values.tax_amount]);

    useEffect(() => {
        return
        let taxAmount = 0;
        cart.items.forEach((item, i) => {
            let taxRatePercent = values.tax_rate_global || (item.product || {}).tax_rate_percent || 0;
            if (taxRatePercent < 0) {
                taxRatePercent = 0;
            }
            let finalPrice = (calcPrice(item.product || {}, item.featureList).final_price || 0) * (item.count || 0);
            if (values.ecommerce_store_promocode_discount_percent) {
                finalPrice = finalPrice * (1 - ((values.ecommerce_store_promocode_discount_percent || 0) / 100));
            } else if (values.ecommerce_store_promocode_discount) {
                finalPrice = (values.ecommerce_store_promocode_discount > finalPrice) ? 0 : (finalPrice - values.ecommerce_store_promocode_discount);
            }
            const taxValue = finalPrice / 100 * taxRatePercent;
            taxAmount += taxValue;
        });

        setFieldValue("tax_amount", taxAmount);
    }, [values.base_amount, values.tax_rate_global, values.ecommerce_store_promocode_discount_percent, values.ecommerce_store_promocode_discount]);

    useEffect(() => {
        //setStep(2)
        if (!stepMapping[stepName] && stepName) {
            dispatch("/");
        }
        setStep(stepMapping[stepName] || step);
    }, [stepName]);

    // Payment V1
    //useEffect(() => {
    //  console.log(0)
    //  const timer = setTimeout(() => {
    //      setPaymentStatusCheckAt(new Date().toISOString());
    //  }, 2000);
    //  return () => clearTimeout(timer);
    //}, [externalTransactionId, paymentStatusCheckAt]);

    // Payment V1
    //useEffect(() => {
    //    console.log(1)
    //    if (externalTransactionId && paymentResultPolling) onCheckPaymentStatus(externalTransactionId);
    //}, [paymentStatusCheckAt]);


    // Reset Session Hard in 30 mins
    useEffect(() => {
        let timer = setTimeout(() => onResetSessionHard(), 30 * 60000);
        return () => clearTimeout(timer);
    }, []);


    // Reset Session if cart changed
    useEffect(() => {
        if (values.ecommerce_order_id && !values.is_payment_done) {
            onResetSession();
        }
    }, [values.total_amount]);

    useEffect(() => {
        if (step < 3) {
            contactData();
        }
    }, [login.token]);




    useEffect(() => {
        window.scrollTo(0, 0);
        if (isDeliverySelected) {
            const deliveryType = deliveryItem.delivery_type;
            setFieldValue("ecommerce_store_delivery_option_id", deliveryItem.ecommerce_store_delivery_option_id);
            setFieldValue("delivery_type", deliveryType);
            if (deliveryType === "shipping_event") {
                setFieldValue("ecommerce_store_delivery_option_event_id", deliveryItem.ecommerce_store_delivery_option_event_id);
            }
            if (deliveryType === "event") {
                setFieldValue("ecommerce_store_delivery_option_event_id", deliveryItem.ecommerce_store_delivery_option_event_id);
                setFieldValue("delivery_requested_time_exact", "12:00:00");
            }
            if (deliveryType === "delivery" || deliveryType === "shipping" || deliveryType === "shipping_event") {
                setFieldValue("delivery_postal_code", deliveryItem.postal_code || "");
                setFieldValue("delivery_requested_date", deliveryItem.delivery_requested_date || null);
            }
            if (deliveryType === "pick_up") {
                setFieldValue("ecommerce_store_delivery_option_pickup_id", deliveryItem.ecommerce_store_delivery_option_pickup_id);
            }
        }
    }, []);


    console.log("VALUES", values, isSubmitting)

    if (step === 0) {
        return (
            <div className="u-sizeFullWidth u-marginTop40 u-flexColumn u-alignItemsCenter u-justifyContentCenter u-paddingBottom100">
                <div className="u-sizeFullWidth u-maxWidth400">
                    <Authorization {...props} onNextStep={onNextStep}/>
                </div>
            </div>
        );
    }

    console.log("ERRS", errors)


    return (
            <React.Fragment>
                <div className="checkout-step-form u-flexColumn u-paddingTop5 u-marginTop10" >
                <div className="u-marginTop10 u-paddingBottom25 u-xs-margin0 u-borderBottom1 u-borderColorLightest u-flexCenter u-justifyContentCenter">
                    <Stepper activeStep={(step - 1 - getDisabledSteps(deliveryItem).length)} className="u-xs-paddingHorizontal0 u-maxWidth780 u-sizeFullWidth" alternativeLabel={isMobile}>
                        {steps.map((stepObj, index) => {
                            const disabledSteps = getDisabledSteps(deliveryItem);
                            if (disabledSteps.includes(stepObj.step)) {
                                return null;
                            }
                            return (
                                <Step key={stepObj.title} className={(stepObj.step < step ? "u-cursorPointer" : "")} onClick={() => stepObj.step < step ? goToStep(stepObj.step) : null}>
                                    <StepLabel>{stepObj.title}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className="u-marginTop20">
                    {renderForm()}
                </div>
            </div>
                {
                        getStep() === 100 ?
                            <div className="u-sizeFullWidth u-marginTop50">
                                <UpsellingV2    {...props}
                                                columns={3}
                                                size={6}
                                                isMobile
                                                categoryId="c3d7c9a6-2dd8-4b46-b01a-449d31f05c22"
                                                label={(
                                                    <div className={isMobile ? "u-absolute u-top0 u-sizeFullWidth u-flexCenter u-justifyContentCenter" : ""}>
                                                        <div className="u-textColorPrimary u-paddingHorizontal20 u-paddingVertical5" style={{fontFamily: "Piroshky", letterSpacing: "2px"}}>More Products</div>
                                                    </div>
                                                )}

                                />
                            </div>
                        :
                        null
                    }

            </React.Fragment>
    );
}

export const defaultValues = (props) => {
    const data = {};
    // INFO
    data.customer_name = "";
    data.customer_surname = "";
    data.customer_phone = "";
    data.customer_email = "";
    data.customer_note = "";

    // DELIVERY
    data.ecommerce_store_delivery_option_id = "";
    data.delivery_type = ""; //pick_up delivery shipping
    data.delivery_requested_date = null;
    data.delivery_requested_time = "";
    data.delivery_requested_time_exact = null;
    //pick_up
    data.ecommerce_store_delivery_option_pickup_id = "";
    //delivery shipping
    data.delivery_name = "";
    data.delivery_surname = "";
    data.delivery_address = "";
    data.delivery_address2 = "";
    data.delivery_city = "";
    data.delivery_state = "";
    data.delivery_postal_code = "";
    data.delivery_phone = "";
    data.delivery_address_verified = false;
    //shipping_air air
    data.delivery_airport_name = "";
    data.ecommerce_store_delivery_option_rule_id = "";
    //event
    data.ecommerce_store_delivery_option_event_id = "";

    // PAYMENT
    data.base_amount = 0;
    data.promocode_amount = 0;
    data.gift_card_amount = 0;
    data.delivery_amount = 0;
    data.packaging_amount = 0;
    data.labeling_amount = 0;
    data.tax_amount = 0;
    data.tips_amount = 0;
    data.total_amount = 0;
    data.tips_amount_percent = 0;
    data.tips_amount_custom = 0

    data.billing_name = "";
    data.billing_address = "";
    data.billing_postal_code = "";

    data.is_payment = false;
    data.is_payment_done = false;
    data.is_payment_send = false;
    data.step = 1;
    data.payment = {};
    data.ecommerce_store_delivery_option = {};
    data.ecommerce_store_delivery_option_rule = {};
    data.ecommerce_store_delivery_option_event = {};
    data.ecommerce_store_delivery_option_pickup = {};
    data.event_date_selected = initDate();
    data.tax_rate_global = 0;

    data.min_order = 0;

    data.is_frozen = false

    data.ecommerce_store_promocode_discount_percent = 0;
    data.ecommerce_store_promocode_discount = 0;
    data.ecommerce_store_promocode = {};
    data.ecommerce_store_promocode_code = "";

    data.ecommerce_store_gift_card_list = [];

    data.labeling_cost = 0;

    data.is_gift = false
    data.gift_sender_name = ""
    data.gift_recipient_name = ""
    data.gift_message = ""

    data.ecommerce_order_id = "";
    data.promocode = ""
    data.promocode_error = ""

    const preorderEvent = getFromLS("preorderEventId") || {};
    if (preorderEvent.start_at) {
      //const eventDate = new Date(preorderEvent.start_at)
      //eventDate.setHours(12);
      //data.event_date_selected = eventDate
      //data.ecommerce_store_delivery_option_event_id = preorderEvent.ecommerce_store_delivery_option_event_id || ""
      //data.ecommerce_store_delivery_option_id = preorderEvent.ecommerce_store_delivery_option_id || ""
    }
    return data;
};


export default CheckoutStepForm = withFormik({
    mapPropsToValues: (props) => (defaultValues(props)),

    validate: (values, props) => {
        let errors = {...(values.validation_errors || {})};

        if (values.step === 3 || values.step === 4) {
            // INFO
            if (!values.customer_name) {
                errors.customer_name = "Cannot be empty";
            }
            if (!values.customer_surname) {
                errors.customer_surname = "Cannot be empty";
            }
            if (!values.customer_email) {
                errors.customer_email = "Cannot be empty";
            }
            if (values.customer_email && !emailRe.test(values.customer_email)) {
                errors.customer_email = "Incorrect Email";
            }
            if (!values.customer_phone) {
                errors.customer_phone = "Cannot be empty";
            } else if (!values.customer_phone || ![10, 11].includes(values.customer_phone.replace(/[^0-9]/g, "").length)) {
                errors.customer_phone = "Incorrect phone";
            }
        }

        if (values.step === 4) {
            // PAYMENT
            if (!values.billing_name && !props.isV2) {
                errors.billing_name = "Cannot be empty";
            }
            if (values.billing_name.length >= 75 && !props.isV2) {
                errors.billing_name = "Max length: 75";
            }
            if (!values.billing_address && !props.isV2) {
                errors.billing_address = "Cannot be empty";
            }
            if (!values.billing_postal_code && !props.isV2) {
                errors.billing_postal_code = "Cannot be empty";
            }
        }

        if (values.step === 2) {
            let dynamicBaseAmount = 0;
            props.cart.items.forEach(item => {
                dynamicBaseAmount += (calcPrice(item.product || {}, item.featureList).final_price || 0) * (item.count || 0)
            });
            if (dynamicBaseAmount < values.min_order) {
                errors.min_order = "Minimum order amount error";
            }
        }

        if (values.step === 2 || values.step === 4) {
            // DELIVERY

            let isUnavailable = false;
            const unavailableProductDict = {};
            const deliveryDateString = values.delivery_type === "event" ? values.event_date_selected : values.delivery_requested_date;
            const deliveryDate = deliveryDateString ? new Date(deliveryDateString) : null;
            props.cart.items.forEach((v, i) => {
                const product = v.product || {};
                if (deliveryDate && product.product_available_from) {
                    const dateFrom = new Date(product.product_available_from);
                    dateFrom.setHours(0, 1);
                    deliveryDate.setHours(12);
                    if (deliveryDate.getTime() < dateFrom.getTime()) {
                        isUnavailable = true;
                        unavailableProductDict[product.ecommerce_product_id] = product.name;
                    }
                }
                if (deliveryDate && product.product_available_till) {
                    const dateTill = new Date(product.product_available_till);
                    dateTill.setHours(23, 59);
                    deliveryDate.setHours(12);
                    if (deliveryDate.getTime() > dateTill.getTime()) {
                        isUnavailable = true;
                        unavailableProductDict[product.ecommerce_product_id] = product.name;
                    }
                }
            });
            if (isUnavailable) {
                const unavailableProductsText = Object.keys(unavailableProductDict).map(key => unavailableProductDict[key]).join(", ");
                const unavailableError = "One of your cart items (" + unavailableProductsText + ") is unavailable for fulfillment for above date. Please either select available date or remove item from your cart";
                if (values.delivery_type === "event" && values.ecommerce_store_delivery_option_event_id) {
                    errors.ecommerce_store_delivery_option_event_id = unavailableError;
                } else {
                    errors.delivery_requested_date = unavailableError;
                }
            }

            
            if (!values.ecommerce_store_delivery_option_id) {
                errors.ecommerce_store_delivery_option_id = "Select Delivery Option";
            }

            if (values.delivery_type === "pick_up") {
                if (!values.ecommerce_store_delivery_option_pickup_id) {
                    errors.ecommerce_store_delivery_option_pickup_id = "Select Pick Up point";
                }
                if (!values.delivery_requested_date) {
                    errors.delivery_requested_date = "Select Requested Date";
                }
                if (!values.delivery_requested_time) {
                    errors.delivery_requested_time = "Select Requested Time";
                }
            } else if (values.delivery_type === "delivery" || values.delivery_type === "shipping" || values.delivery_type === "shipping_event") {
                if (!values.delivery_name) {
                    errors.delivery_name = "Cannot be empty";
                }
                if (!values.delivery_surname) {
                    errors.delivery_surname = "Cannot be empty";
                }
                if (!values.delivery_address) {
                    errors.delivery_address = "Cannot be empty";
                }
                if (!values.delivery_city) {
                    errors.delivery_city = "Cannot be empty";
                }
                if (!values.delivery_state) {
                    errors.delivery_state = "Cannot be empty";
                }
                if (!values.delivery_postal_code) {
                    errors.delivery_postal_code = "Cannot be empty";
                }
                if (!values.delivery_phone) {
                    errors.delivery_phone = "Cannot be empty";
                } else if (!values.delivery_phone || ![10, 11].includes(values.delivery_phone.replace(/[^0-9]/g, "").length)) {
                    errors.delivery_phone = "Incorrect phone";
                }
                if (!values.delivery_requested_date) {
                    errors.delivery_requested_date = "Select Requested Date";
                }
                if (values.delivery_type === "delivery" && !values.delivery_requested_time && !props.item_time_disable) {
                    errors.delivery_requested_time = "Select Requested Time";
                }
                if (values.delivery_type === "delivery" && Object.keys(values.ecommerce_store_delivery_option_rule || {}).length === 0 && !!values.delivery_postal_code) {
                    errors.delivery_postal_code = "The service you have selected is not available on the selected date. Please check out our calendar for a list of events or contact our Customer Service at cs@piroshkybakery.com or 206-764-1000 for delivery options!";
                }
                if (values.delivery_type === "shipping" && values.step === 2 && !values.delivery_address_verified) {
                    errors.delivery_address_verified = "Verify delivery address"
                }
                if (!errors.delivery_address_verified && values.delivery_type === "shipping" && values.step === 2 && !values.delivery_amount) {
                    //errors.delivery_address_verified = "Address problem, contact support"
                }
            } else if (values.delivery_type === "shipping_air" || values.delivery_type === "air") {
                if (!values.delivery_requested_date) {
                    errors.delivery_requested_date = "Select Requested Date";
                }

                if (!values.ecommerce_store_delivery_option_rule_id) {
                    errors.ecommerce_store_delivery_option_rule_id = "Select Airport";
                }
            } else if (values.delivery_type === "event") {
                if (!values.delivery_requested_time_exact) {
                    errors.delivery_requested_time_exact = "Select Requested Time";
                }

                if (!values.ecommerce_store_delivery_option_event_id) {
                    errors.ecommerce_store_delivery_option_event_id = "Select Event";
                }
            }
        }

        return errors;
    },

    handleSubmit: async (values, props) => {
        const {token, isV2, customerMeta, paymentResultPolling, checkoutType, paymentDynamic, cart, onSuccess, ecommerceStoreId, ecommerceStoreGroupId, dispatch, apiPath} = props.props;
        console.log("SUBMIT")
        //if (values.is_payment_send) {
        //    console.log("ORDER SENT");
        //    return;
        //}
        if (values.is_payment_done) {
            console.log("Payment done")
            //props.setFieldValue("is_payment", true)

            //props.setFieldValue("is_payment_send", true);
            console.log("START ORDER CONFIRMATION", values.ecommerce_order_id)
            const resp = await dataSourceV2("ecommerce_order_confirm", {data: {ecommerce_order_id: values.ecommerce_order_id, payment: values.payment || {}}, url: apiPath})
            if (resp.message && !resp.message.toLowerCase().includes("confirmed")) {
                console.log("ERROR ORDER CONFIRM", resp.message);
                errorNotification((resp.message || "") + (resp.status || "").toString());
            } else {
                console.log("ORDER CONFIRMED", values.ecommerce_order_id)
                dispatch("cart", {}, "clear_cart");
                onSuccess({ecommerce_order: values});
                console.log("stop submitting payment done")
                props.setSubmitting(false);
                try {
                    fbq('trackCustom', 'PlaceAnOrder', {
                      value: values.total_amount,
                      currency: 'USD',
                      order_id: values.ecommerce_order_id || "",
                      event_id: values.ecommerce_order_id || ""
                    });
                } catch (error) {}
                return;
            }

        } else if (values.is_payment_send) {
            console.log("ORDER SENT");
            return;
        } else {
          console.log("DONE 3")
            props.setFieldValue("is_payment_send", true);
            const cartItemList = cart.items.map(v => {
                const product = v.product || {};
                return {
                    ...v,
                    count: v.count,
                    product_id: v.id,
                    price: parseFloat(v.price || 0),
                    price_string: (v.price || 0).toString(),
                    name: product.name,
                    image: product.image,
                    featureDict: v.featureDict || {},
                    featureList: v.featureList || []
                };
            });
            const order = {...values};
            delete order["is_payment"];
            delete order["is_payment_done"];
            delete order["is_payment_send"];
            delete order["step"];
            delete order["event_date_selected"];
            delete order["promocode"];
            delete order["promocode_error"];
            delete order["tips_amount_custom"];

            if (order.delivery_type !== "event") {
                delete order["ecommerce_store_delivery_option_event_id"];
                delete order["event_date_selected"];
            }

            if (order.delivery_type === "event") {
                if (values.event_date_selected) {
                    const event_date_selected = new Date(values.event_date_selected);
                    order.delivery_requested_date = event_date_selected.toISOString();
                }

                order.city_filter = (order.ecommerce_store_delivery_option_event || {}).city;
                order.state_filter = (order.ecommerce_store_delivery_option_event || {}).state;
                order.postal_code_filter = (order.ecommerce_store_delivery_option_event || {}).postal_code;
            } else if (order.delivery_type === "pick_up") {
                order.city_filter = (order.ecommerce_store_delivery_option_pickup || {}).city;
                order.state_filter = (order.ecommerce_store_delivery_option_pickup || {}).state;
                order.postal_code_filter = (order.ecommerce_store_delivery_option_pickup || {}).postal_code;
            } else if (order.delivery_type === "delivery" || order.delivery_type === "shipping") {
                order.city_filter = order.delivery_city;
                order.state_filter = order.delivery_state;
                order.postal_code_filter = order.delivery_postal_code;
            } else if (order.delivery_type === "air" || order.delivery_type === "shipping_air") {

            }


            const paymentJson = JSON.stringify(order.payment || {});
            order.payment = paymentJson;
            const ecommerceStoreDeliveryOptionJson = JSON.stringify(order.ecommerce_store_delivery_option || {});
            order.ecommerce_store_delivery_option_json = ecommerceStoreDeliveryOptionJson;
            delete order["ecommerce_store_delivery_option"];
            const ecommerceStoreDeliveryOptionRuleJson = JSON.stringify(order.ecommerce_store_delivery_option_rule || {});
            order.ecommerce_store_delivery_option_rule_json = ecommerceStoreDeliveryOptionRuleJson;
            delete order["ecommerce_store_delivery_option_rule"];
            const ecommerceStoreDeliveryOptionEventJson = JSON.stringify(order.ecommerce_store_delivery_option_event || {});
            order.ecommerce_store_delivery_option_event_json = ecommerceStoreDeliveryOptionEventJson;
            delete order["ecommerce_store_delivery_option_event"];
            const ecommerceStoreDeliveryOptionPickupJson = JSON.stringify(order.ecommerce_store_delivery_option_pickup || {});
            order.ecommerce_store_delivery_option_pickup_json = ecommerceStoreDeliveryOptionPickupJson;
            delete order["ecommerce_store_delivery_option_pickup"];
            const ecommerceStorePromocodeJson = JSON.stringify(order.ecommerce_store_promocode || {});
            order.ecommerce_store_promocode_json = ecommerceStorePromocodeJson;
            order.ecommerce_store_promocode_code = (order.ecommerce_store_promocode || {}).code || ""
            //delete order["ecommerce_store_promocode"];
            delete order["ecommerce_order_id"];

            const ecommerceStoreGiftCardsJson = JSON.stringify(order.ecommerce_store_gift_card_list || {});
            order.ecommerce_store_gift_cards_json = ecommerceStoreGiftCardsJson;
            delete order["ecommerce_store_gift_card_list"];

            delete order["labeling_cost"];
            
            delete order["delivery_address_verified"];

            delete order["validation_errors"]

            order.status = "unpaid";

            order.device = window.innerWidth < 768 ? 'mobile' : 'desktop';
            order.express_rendered = "true";
            order.checkout_type = "regular";

            const data = {
                ecommerce_order: {...order, ecommerce_store_id: ecommerceStoreId, ecommerce_store_group_id: ecommerceStoreGroupId},
                token,
                cart_item_list: cartItemList,
                utm: getUTM()
            };

            //data.ecommerce_order.payment_type = details.payment_type || "";

            const errorNotification = (message) => {
                const subject = "PAYMENT ERROR. Unable to create unpaid order." + (message || "");
                dataSourceV2("customer_message_create", {
                  data: {
                    item: {
                      name: values.customer_name + " " + values.customer_surname,
                      email: values.customer_email,
                      phone: values.customer_phone,
                      subject: subject,
                      content: subject + " | " + JSON.stringify(data),
                      ecommerce_store_id: ecommerceStoreId,
                      email_to: "cs@piroshkybakery.com",
                    },
                  },
                  url: apiPath,
                });
            };

            const unpaidResp = await dataSourceV2("ecommerce_order_unpaid", {data: {item: data}, url: apiPath})
            if (unpaidResp.message || unpaidResp.status) {
                errorNotification((unpaidResp.message || "") + (unpaidResp.status || "").toString());
                console.log("ERROR", unpaidResp.message);
            } else {
                const item = unpaidResp.item || {};
                const ecommerceOrderId = item.ecommerce_order_id;
                await props.setFieldValue("ecommerce_order_id", ecommerceOrderId);
                //props.setFieldValue("is_payment", true)
                //dispatch("cart", {}, "clear_cart")
                //onSuccess({order: data, ...values, customerMeta})

                //dispatch("cart", {}, "clear_cart");
                //onSuccess({ecommerce_order: values});
            }
            //props.setSubmitting(false);

            if (!paymentResultPolling && !isV2) {
                dataSourceV2(paymentDynamic ? "customer_payment_dynamic_success" : "customer_payment_success", {data: {ecommerce_store_id: ecommerceStoreId, item: values.payment}, url: apiPath})
                    .then(v => {
                        if (v.message || v.status) {
                            console.log("ERROR", v.message);
                        } else {
                            console.log(values.payment);
                        }
                        console.log("stop submitting v1 success")
                        props.setSubmitting(false);
                    });
            }
        }


    },
    //enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false

})(CheckoutStepForm);



function getUTM() {
    try {
        const utmData = localStorage.getItem('_utm_meta');
        return utmData ? JSON.parse(utmData) : {};
    } catch (e) {
        console.error('Error retrieving UTM parameters from local storage:', e);
        return {};
    }
}